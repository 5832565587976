import { ReactNode } from 'react';
import { Rss, Wallet } from 'lucide-react';

import { AnalyticsIcon, DocumentIcon, HelpIcon, MembersIcon, SettingsIcon } from 'components/icons';
import { ActivityIcon, MembersSidebarIcon } from 'components/icons/v2';

export interface IRoutes {
  name: string;
  icon?: ReactNode;
  title?: string;
  hasBackButton?: boolean;
  getPath: (...data: string[]) => string;
  resolve: (...data: string[]) => boolean | string;
}

export type RouteType =
  'HOME'
  | 'SIGN_OUT'
  | 'LOGIN'
  | 'REGISTER'
  | 'MEMBER'
  | 'MEMBER_EDIT'
  | 'MEMBER_EDIT_SOCIAL'
  | 'MEMBER_EDIT_SKILLS'
  | 'MEMBER_EDIT_HOBBY'
  | 'MEMBER_EDIT_LOCATION'
  | 'MEMBER_EDIT_EXPERIENCE_ADD'
  | 'MEMBER_EDIT_EXPERIENCE_EDIT'
  | 'MEMBER_EDIT_FEATURED_ADD'
  | 'MEMBER_EDIT_FEATURED_EDIT'
  | 'MEMBER_EDIT_EDUCATION_ADD'
  | 'MEMBER_EDIT_EDUCATION_EDIT'
  | 'MEMBER_COMPANY'
  | 'MEMBER_COMPANY_ADD'
  | 'MEMBER_POSITION'
  | 'MEMBER_QUALIFICATION'
  | 'MEMBER_UNIVERSITY'
  | 'MEMBERS'
  | 'REQUESTS'
  | 'OAUTH'
  | 'TWA'
  | 'PANEL'
  | 'ANALYTICS'
  | 'REACH'
  | 'HELP'
  | 'LANDING_BUILDER'
  | 'SETTINGS'
  | 'APPLY'
  | 'CREATE_COMMUNITY'
  | 'SELECT_GOAL'
  | 'PENDING'
  | 'RULES'
  | 'START'
  | 'BLOG'
  | 'PAYMENTS'
  | 'RESET_PASSWORD'
  | 'ACTIVITY';

export type MemberRouteType = 'SUBSCRIPTION' | 'MEMBERS' | 'ACTIVITY' | 'MEMBER_PROFILE' | 'WELCOME' | 'WELCOME_LOADING' | 'MY_PROFILE';

const HOME: IRoutes = {
  name: 'home',
  getPath: () => '/',
  resolve: (url: string) => url === OWNER_ROUTES.HOME.getPath()
};
const SIGN_OUT: IRoutes = {
  name: 'sign-out',
  getPath: () => '/sign-out',
  resolve: (url: string) => url === OWNER_ROUTES.SIGN_OUT.getPath()
};
const LOGIN: IRoutes = {
  name: 'login',
  getPath: () => '/login',
  resolve: (url: string) => url === OWNER_ROUTES.LOGIN.getPath()
};
const REGISTER: IRoutes = {
  name: 'register',
  getPath: () => '/register',
  resolve: (url: string) => url === OWNER_ROUTES.REGISTER.getPath()
};
const MEMBER: IRoutes = {
  name: 'member-profile',
  getPath: (id: string) => `/members/${id}`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_EDIT: IRoutes = {
  name: 'member-profile-edit',
  getPath: (id: string) => `/members/${id}/edit`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_EDIT_SOCIAL: IRoutes = {
  name: 'member-profile-social-edit',
  getPath: (id: string) => `/members/${id}/edit/social`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_EDIT_SKILLS: IRoutes = {
  name: 'member-profile-skils-edit',
  getPath: (id: string) => `/members/${id}/edit/skills`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_EDIT_HOBBY: IRoutes = {
  name: 'member-profile-hobby-edit',
  getPath: (id: string) => `/members/${id}/edit/hobby`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_EDIT_LOCATION: IRoutes = {
  name: 'member-profile-location-edit',
  getPath: (id: string) => `/members/${id}/edit/location`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_EDIT_EXPERIENCE_ADD: IRoutes = {
  name: 'member-profile-experience-add',
  getPath: (id: string) => `/members/${id}/edit/experience/add`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_EDIT_EXPERIENCE_EDIT: IRoutes = {
  name: 'member-profile-experience-edit',
  getPath: (id: string, experienceId: string) =>
    `/members/${id}/edit/experience/${experienceId}/edit`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_EDIT_FEATURED_ADD: IRoutes = {
  name: 'member-profile-featured-add',
  getPath: (id: string) => `/members/${id}/edit/featured/add`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_EDIT_FEATURED_EDIT: IRoutes = {
  name: 'member-profile-featured-edit',
  getPath: (id: string, featuredId: string) =>
    `/members/${id}/edit/featured/${featuredId}/edit`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_EDIT_EDUCATION_ADD: IRoutes = {
  name: 'member-profile-education-add',
  getPath: (id: string) => `/members/${id}/edit/education/add`,
  resolve: (url: string) => url.endsWith('/edit/education/add')
};
const MEMBER_EDIT_EDUCATION_EDIT: IRoutes = {
  name: 'member-profile-education-edit',
  getPath: (id: string, educationId: string) =>
    `/members/${id}/edit/education/${educationId}/edit`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_COMPANY: IRoutes = {
  name: 'member-profile-company',
  getPath: (id: string) => `/members/${id}/company`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_COMPANY_ADD: IRoutes = {
  name: 'member-profile-company-add',
  getPath: (id: string) => `/members/${id}/company/add`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_POSITION: IRoutes = {
  name: 'member-profile-position',
  getPath: (id: string) => `/members/${id}/position`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_QUALIFICATION: IRoutes = {
  name: 'member-profile-qualification',
  getPath: (id: string) => `/members/${id}/qualification`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBER_UNIVERSITY: IRoutes = {
  name: 'member-profile-university',
  getPath: (id: string) => `/members/${id}/university`,
  resolve: (url: string) => url.startsWith('/members/')
};
const MEMBERS: IRoutes = {
  name: 'members',
  title: 'Members',
  getPath: () => '/members',
  resolve: (url: string) => url === OWNER_ROUTES.MEMBERS.getPath(),
  icon: <MembersSidebarIcon />,
};
const REQUESTS: IRoutes = {
  name: 'requests',
  getPath: () => '/requests',
  resolve: (url: string) => url === OWNER_ROUTES.REQUESTS.getPath()
};
const OAUTH: IRoutes = {
  name: 'oauth-complete',
  getPath: () => '/i/oauth-complete',
  resolve: (url: string) => url === OWNER_ROUTES.OAUTH.getPath()
};
const TWA: IRoutes = {
  name: 'telegram-web-app',
  getPath: () => '/twa',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.TWA.getPath())
};
const PANEL: IRoutes = {
  name: 'panel',
  title: 'Applications',
  icon: <MembersIcon width={24} height={24} />,
  getPath: () => '/panel',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.PANEL.getPath())
};
const ANALYTICS: IRoutes = {
  name: 'analytics',
  getPath: () => '/analytics',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.ANALYTICS.getPath()),
  title: 'Analytics',
  icon: <AnalyticsIcon width={24} height={24} />
};
const REACH: IRoutes = {
  getPath: () => '/reach',
  name: 'reach',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.REACH.getPath()),
  title: 'Reach out'
};
const HELP: IRoutes = {
  name: 'help',
  icon: <HelpIcon width={'24'} height={'24'} />,
  title: 'Get help',
  getPath: () => '/communityInfo',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.HELP.getPath())
};
const LANDING_BUILDER: IRoutes = {
  name: 'landing',
  title: 'Landing',
  getPath: () => '/landing',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.LANDING_BUILDER.getPath()),
  icon: <DocumentIcon />
};
const SETTINGS: IRoutes = {
  name: 'settings',
  title: 'Settings',
  getPath: () => '/settings',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.SETTINGS.getPath()),
  icon: <SettingsIcon width={24} height={24} />
};
const APPLY: IRoutes = {
  name: 'apply',
  getPath: () => '/apply',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.APPLY.getPath())
};
const CREATE_COMMUNITY: IRoutes = {
  name: 'create-community',
  getPath: () => '/create-community',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.CREATE_COMMUNITY.getPath())
};
const SELECT_GOAL: IRoutes = {
  name: 'goal',
  getPath: () => '/goal',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.SELECT_GOAL.getPath())
};
const PENDING: IRoutes = {
  name: 'pending',
  getPath: () => '/pending',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.PENDING.getPath())
};
const RULES: IRoutes = {
  name: 'rules',
  getPath: () => '/rules',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.RULES.getPath())
};
const START: IRoutes = {
  name: 'start',
  getPath: () => '/start',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.START.getPath())
};
const BLOG: IRoutes = {
  name: 'blog',
  title: 'Blog',
  getPath: () => '/blog',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.BLOG.getPath()),
  icon: <Rss />
};
const PAYMENTS: IRoutes = {
  name: 'payments',
  icon: <Wallet />,
  title: 'Payments',
  getPath: () => '/payments',
  resolve: (url: string) => url.startsWith(OWNER_ROUTES.PAYMENTS.getPath())
};

const SUBSCRIPTION: IRoutes = {
  name: 'subscription',
  title: 'Subscription',
  getPath: () => '/subscription',
  resolve: (url: string) => url.startsWith('/subscription'),
  icon: <Rss />
}

const ACTIVITY: IRoutes = {
  name: 'activity',
  title: 'Activity',
  getPath: () => '/activity',
  resolve: (url: string) => url.startsWith('/activity'),
  icon: <ActivityIcon />
}

const MEMBER_PROFILE: IRoutes = {
  name: 'member-profile',
  title: 'Activity',
  getPath: (id: string) => `/members/${id}`,
  resolve: (url: string) => url.startsWith('/members/')
};

const WELCOME: IRoutes = {
  name: 'welcome',
  getPath: () => '/welcome',
  resolve: (url: string) => url.startsWith('/welcome')
};

const WELCOME_LOADING: IRoutes = {
  name: 'welcome',
  getPath: () => '/welcome/loading',
  resolve: (url: string) => url.includes('/welcome/loading'),
};

const MY_PROFILE: IRoutes = {
  name: 'my-profile',
  getPath: () => '/my-profile',
  resolve: (url: string) => url.startsWith('/my-profile'),
};

const RESET_PASSWORD: IRoutes = {
  name: 'reset-password',
  getPath: () => '/reset-password',
  resolve: (url: string) => url.startsWith('/reset-password'),
};

export const OWNER_ROUTES: Record<RouteType, IRoutes> = {
  HOME,
  SIGN_OUT,
  LOGIN,
  REGISTER,
  MEMBER,
  MEMBER_EDIT,
  MEMBER_EDIT_SOCIAL,
  MEMBER_EDIT_SKILLS,
  MEMBER_EDIT_HOBBY,
  MEMBER_EDIT_LOCATION,
  MEMBER_EDIT_EXPERIENCE_ADD,
  MEMBER_EDIT_EXPERIENCE_EDIT,
  MEMBER_EDIT_FEATURED_ADD,
  MEMBER_EDIT_FEATURED_EDIT,
  MEMBER_EDIT_EDUCATION_ADD,
  MEMBER_EDIT_EDUCATION_EDIT,
  MEMBER_COMPANY,
  MEMBER_COMPANY_ADD,
  MEMBER_POSITION,
  MEMBER_QUALIFICATION,
  MEMBER_UNIVERSITY,
  MEMBERS,
  REQUESTS,
  OAUTH,
  TWA,
  PANEL,
  ANALYTICS,
  REACH,
  HELP,
  LANDING_BUILDER,
  SETTINGS,
  APPLY,
  CREATE_COMMUNITY,
  SELECT_GOAL,
  PENDING,
  RULES,
  START,
  BLOG,
  PAYMENTS,
  RESET_PASSWORD,
  ACTIVITY,
};

export const MEMBER_ROUTES: Record<MemberRouteType, IRoutes> = {
  ACTIVITY,
  SUBSCRIPTION,
  MEMBERS,
  MEMBER_PROFILE,
  WELCOME,
  WELCOME_LOADING,
  MY_PROFILE,
};

export const OWNER_SIDEBAR_ROUTES: IRoutes[] = [
  OWNER_ROUTES.ANALYTICS,
  OWNER_ROUTES.LANDING_BUILDER,
  OWNER_ROUTES.PANEL,
  OWNER_ROUTES.BLOG,
  OWNER_ROUTES.PAYMENTS,
  OWNER_ROUTES.SETTINGS,
];

export const MEMBER_SIDEBAR_ROUTES: IRoutes[] = [
  MEMBER_ROUTES.MEMBERS,
  MEMBER_ROUTES.ACTIVITY,
];

export const APP_ROUTES: IRoutes[] = Object.values(OWNER_ROUTES);

export const resolveRoute = (url: string) => {
  return Object.values(OWNER_ROUTES).find((route) => route.resolve(url));
};

export const getRouteSearchParams = (url: string): Record<string, string> => {
  const params = url.split('?')[1];
  if (!params) return {};

  return params.split('&').reduce((acc: Record<string, string>, item: string) => {
    const [key, value] = item.split('=');
    acc[key] = value;
    return acc;
  }, {});
}

export const buildRoutePath = (url: string, additionalSearchParams: Record<string, string>) => {
  const params = { ...getRouteSearchParams(url), ...additionalSearchParams };
  const search = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');

  return `${url.split('?')[0]}?${search}`;
}
