import { IconProps } from 'components/icons/types';

export const RedirectIcon = (props: IconProps) => {
  const { width = 48, height = 48, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 48 48" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 26C9.43333 26 8.95833 25.8083 8.575 25.425C8.19167 25.0417 8 24.5667 8 24C8 23.4333 8.19167 22.9583 8.575 22.575C8.95833 22.1917 9.43333 22 10 22H23.2L33.2 12H30C29.4333 12 28.9583 11.8083 28.575 11.425C28.1917 11.0417 28 10.5667 28 10C28 9.43333 28.1917 8.95833 28.575 8.575C28.9583 8.19167 29.4333 8 30 8H38C38.5667 8 39.0417 8.19167 39.425 8.575C39.8083 8.95833 40 9.43333 40 10V18C40 18.5667 39.8083 19.0417 39.425 19.425C39.0417 19.8083 38.5667 20 38 20C37.4333 20 36.9583 19.8083 36.575 19.425C36.1917 19.0417 36 18.5667 36 18V14.8L25.95 24.85C25.5833 25.2167 25.1583 25.5 24.675 25.7C24.1917 25.9 23.6833 26 23.15 26H10ZM30 40C29.4333 40 28.9583 39.8083 28.575 39.425C28.1917 39.0417 28 38.5667 28 38C28 37.4333 28.1917 36.9583 28.575 36.575C28.9583 36.1917 29.4333 36 30 36H33.2L28.25 31.1C27.85 30.7 27.65 30.225 27.65 29.675C27.65 29.125 27.85 28.65 28.25 28.25C28.65 27.85 29.1333 27.65 29.7 27.65C30.2667 27.65 30.75 27.85 31.15 28.25L36 33.2V30C36 29.4333 36.1917 28.9583 36.575 28.575C36.9583 28.1917 37.4333 28 38 28C38.5667 28 39.0417 28.1917 39.425 28.575C39.8083 28.9583 40 29.4333 40 30V38C40 38.5667 39.8083 39.0417 39.425 39.425C39.0417 39.8083 38.5667 40 38 40H30Z"
        fill="currentColor" />
    </svg>
  )
};
