import { IconProps } from 'components/icons/types';

export const ChevronIcon = (props: IconProps) => {
  const { width = 20, height = 20, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5013 12.4779C10.3902 12.4779 10.286 12.4605 10.1888 12.4258C10.0916 12.3911 10.0013 12.332 9.91797 12.2487L6.08464 8.41536C5.93186 8.26259 5.85547 8.06814 5.85547 7.83203C5.85547 7.59592 5.93186 7.40148 6.08464 7.2487C6.23741 7.09592 6.43186 7.01953 6.66797 7.01953C6.90408 7.01953 7.09852 7.09592 7.2513 7.2487L10.5013 10.4987L13.7513 7.2487C13.9041 7.09592 14.0985 7.01953 14.3346 7.01953C14.5707 7.01953 14.7652 7.09592 14.918 7.2487C15.0707 7.40148 15.1471 7.59592 15.1471 7.83203C15.1471 8.06814 15.0707 8.26259 14.918 8.41536L11.0846 12.2487C11.0013 12.332 10.911 12.3911 10.8138 12.4258C10.7166 12.4605 10.6124 12.4779 10.5013 12.4779Z"
        fill="currentColor" />
    </svg>
  )
};
