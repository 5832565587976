import { IconProps } from 'components/icons/types';

export const TelegramIcon = (props: IconProps) => {
  const { width = 146, height = 146, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.4949 35.521C31.1508 29.3068 33.1417 24.6489 35.0242 21.0802C36.9065 17.512 38.6787 15.0367 40.8953 13.186C44.7993 9.92662 49.6348 7.98683 54.7089 7.64463C57.5901 7.45032 60.5816 8.01475 64.408 9.29288C68.235 10.5712 72.8929 12.562 79.1071 15.2179L110.171 28.4947C116.386 31.1506 121.044 33.1415 124.612 35.024C128.18 36.9063 130.656 38.6785 132.506 40.8951C135.766 44.7991 137.706 49.6346 138.048 54.7087C138.242 57.5899 137.678 60.5814 136.4 64.4078C135.121 68.2348 133.13 72.8927 130.475 79.1069L117.198 110.171C114.542 116.385 112.551 121.043 110.668 124.612C108.786 128.18 107.014 130.656 104.797 132.506C100.893 135.766 96.0578 137.705 90.9837 138.048C88.1026 138.242 85.111 137.678 81.2846 136.399C77.4577 135.121 72.7997 133.13 66.5855 130.474L35.5212 117.198C29.307 114.542 24.6491 112.551 21.0804 110.668C17.5122 108.786 15.0368 107.014 13.1862 104.797C9.92681 100.893 7.98703 96.0576 7.64482 90.9835C7.45051 88.1024 8.01494 85.1108 9.29307 81.2844C10.5714 77.4575 12.5621 72.7995 15.2181 66.5853L28.4949 35.521Z"
        fill="url(#paint0_linear_3569_89455)" />
      <path
        d="M28.4949 35.521C31.1508 29.3068 33.1417 24.6489 35.0242 21.0802C36.9065 17.512 38.6787 15.0367 40.8953 13.186C44.7993 9.92662 49.6348 7.98683 54.7089 7.64463C57.5901 7.45032 60.5816 8.01475 64.408 9.29288C68.235 10.5712 72.8929 12.562 79.1071 15.2179L110.171 28.4947C116.386 31.1506 121.044 33.1415 124.612 35.024C128.18 36.9063 130.656 38.6785 132.506 40.8951C135.766 44.7991 137.706 49.6346 138.048 54.7087C138.242 57.5899 137.678 60.5814 136.4 64.4078C135.121 68.2348 133.13 72.8927 130.475 79.1069L117.198 110.171C114.542 116.385 112.551 121.043 110.668 124.612C108.786 128.18 107.014 130.656 104.797 132.506C100.893 135.766 96.0578 137.705 90.9837 138.048C88.1026 138.242 85.111 137.678 81.2846 136.399C77.4577 135.121 72.7997 133.13 66.5855 130.474L35.5212 117.198C29.307 114.542 24.6491 112.551 21.0804 110.668C17.5122 108.786 15.0368 107.014 13.1862 104.797C9.92681 100.893 7.98703 96.0576 7.64482 90.9835C7.45051 88.1024 8.01494 85.1108 9.29307 81.2844C10.5714 77.4575 12.5621 72.7995 15.2181 66.5853L28.4949 35.521Z"
        stroke="#F9F9F9" strokeWidth="0.0965217" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M45.1531 60.3427C62.801 60.2194 74.548 60.1863 80.3943 60.2432C97.0865 60.4056 100.469 60.6403 102.403 61.4269C102.828 61.5999 103.728 62.1079 104.127 62.8654C104.464 63.505 104.364 64.1317 104.263 64.5579C104.161 64.9842 103.859 65.9252 103.489 66.6034C99.2723 74.3449 87.5793 92.5076 82.0258 100.823C79.6758 104.342 77.7192 105.022 76.3018 104.565C73.2216 103.571 71.9248 100.378 69.8586 97.2693C66.6253 92.4052 64.8711 89.4878 61.7392 84.7512C58.1196 79.2773 62.166 78.8484 66.3122 77.0932C67.3973 76.6338 85.5315 70.3376 86.2447 69.3682C86.3339 69.2469 86.5779 68.7572 86.3929 68.3644C86.2079 67.9716 85.7287 67.8741 85.38 67.8104C84.8858 67.7201 75.6833 69.396 57.7724 72.8382C55.1379 73.3663 53.0018 73.2593 51.3642 72.5171C49.5588 71.699 46.4772 69.2099 44.2335 67.2492C41.4815 64.8444 39.1761 63.2094 40.1514 61.5396C40.6594 60.6699 42.3266 60.2709 45.1531 60.3427Z"
            fill="white" />
      <defs>
        <linearGradient id="paint0_linear_3569_89455" x1="94.6582" y1="21.8119" x2="51.0344" y2="123.88"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
      </defs>
    </svg>

  )
};
