import { IconProps } from 'components/icons/types';

export const ChevronRightIcon = (props: IconProps) => {
  const { width = 6, height = 12, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 6 12" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 11L4.58578 7.41421C5.36683 6.63317 5.36683 5.36683 4.58579 4.58579L1 1"
        strokeWidth={1.5} strokeLinecap="round"
        stroke="currentColor" />
    </svg>
  )
};
