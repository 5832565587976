import { IconProps } from 'components/icons/types';

export const KeyConnectIcon = (props: IconProps) => {
  const { width = 16, height = 16, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.332 11.3346C10.532 11.3346 9.82648 11.0819 9.21536 10.5763C8.60425 10.0707 8.22092 9.43464 8.06536 8.66797H4.53203C4.3987 8.96797 4.19314 9.20964 3.91536 9.39297C3.63759 9.5763 3.33203 9.66797 2.9987 9.66797C2.53203 9.66797 2.13759 9.50686 1.81536 9.18464C1.49314 8.86241 1.33203 8.46797 1.33203 8.0013C1.33203 7.53464 1.49314 7.14019 1.81536 6.81797C2.13759 6.49575 2.53203 6.33464 2.9987 6.33464C3.33203 6.33464 3.63759 6.4263 3.91536 6.60964C4.19314 6.79297 4.3987 7.03464 4.53203 7.33464H8.06536C8.22092 6.56797 8.60425 5.93186 9.21536 5.4263C9.82648 4.92075 10.532 4.66797 11.332 4.66797C12.2543 4.66797 13.0404 4.99297 13.6904 5.64297C14.3404 6.29297 14.6654 7.07908 14.6654 8.0013C14.6654 8.92352 14.3404 9.70964 13.6904 10.3596C13.0404 11.0096 12.2543 11.3346 11.332 11.3346ZM11.332 10.0013C11.8876 10.0013 12.3598 9.80686 12.7487 9.41797C13.1376 9.02908 13.332 8.55686 13.332 8.0013C13.332 7.44575 13.1376 6.97352 12.7487 6.58464C12.3598 6.19575 11.8876 6.0013 11.332 6.0013C10.7765 6.0013 10.3043 6.19575 9.91537 6.58464C9.52648 6.97352 9.33203 7.44575 9.33203 8.0013C9.33203 8.55686 9.52648 9.02908 9.91537 9.41797C10.3043 9.80686 10.7765 10.0013 11.332 10.0013Z"
        fill="currentColor" />
    </svg>
  )
};
