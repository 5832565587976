import { IconProps } from 'components/icons/types';

export const HashIcon = (props: IconProps) => {
  const { width = 13, height = 13, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.91856 8.5L4.48887 10.2057C4.46283 10.3186 4.40641 10.4097 4.3196 10.4792C4.2328 10.5486 4.12863 10.5833 4.0071 10.5833C3.84217 10.5833 3.70762 10.5182 3.60346 10.388C3.49929 10.2578 3.46891 10.1146 3.51231 9.95833L3.8769 8.5H2.45762C2.28401 8.5 2.14512 8.43273 2.04096 8.29818C1.93679 8.16363 1.90641 8.01389 1.94981 7.84896C1.97585 7.72743 2.03662 7.63194 2.1321 7.5625C2.22759 7.49306 2.3361 7.45833 2.45762 7.45833H4.13731L4.65815 5.375H3.23887C3.06526 5.375 2.92637 5.30773 2.82221 5.17318C2.71804 5.03863 2.68766 4.88889 2.73106 4.72396C2.7571 4.60243 2.81787 4.50694 2.91335 4.4375C3.00884 4.36806 3.11735 4.33333 3.23887 4.33333H4.91856L5.34825 2.6276C5.37429 2.51476 5.43071 2.42361 5.51752 2.35417C5.60433 2.28472 5.70849 2.25 5.83002 2.25C5.99495 2.25 6.1295 2.3151 6.23367 2.44531C6.33783 2.57552 6.36822 2.71875 6.32481 2.875L5.96023 4.33333H8.04356L8.47325 2.6276C8.49929 2.51476 8.55571 2.42361 8.64252 2.35417C8.72933 2.28472 8.83349 2.25 8.95502 2.25C9.11995 2.25 9.2545 2.3151 9.35867 2.44531C9.46283 2.57552 9.49321 2.71875 9.44981 2.875L9.08523 4.33333H10.5045C10.6781 4.33333 10.817 4.40061 10.9212 4.53516C11.0253 4.6697 11.0557 4.81944 11.0123 4.98438C10.9863 5.1059 10.9255 5.20139 10.83 5.27083C10.7345 5.34028 10.626 5.375 10.5045 5.375H8.82481L8.30398 7.45833H9.72325C9.89686 7.45833 10.0357 7.52561 10.1399 7.66016C10.2441 7.7947 10.2745 7.94444 10.2311 8.10938C10.205 8.2309 10.1443 8.32639 10.0488 8.39583C9.95328 8.46528 9.84478 8.5 9.72325 8.5H8.04356L7.61387 10.2057C7.58783 10.3186 7.53141 10.4097 7.4446 10.4792C7.3578 10.5486 7.25363 10.5833 7.1321 10.5833C6.96717 10.5833 6.83262 10.5182 6.72846 10.388C6.62429 10.2578 6.59391 10.1146 6.63731 9.95833L7.0019 8.5H4.91856ZM5.17898 7.45833H7.26231L7.78315 5.375H5.69981L5.17898 7.45833Z"
        fill="currentColor" />
    </svg>
  )
};
