import { IconProps } from 'components/icons/types';

export const ReturnIcon = (props: IconProps) => {
  const { width = 16, height = 16, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.10156 9.75156L7.27656 11.9266C7.41406 12.0641 7.48281 12.2391 7.48281 12.4516C7.48281 12.6641 7.41406 12.8391 7.27656 12.9766C7.13906 13.1141 6.96406 13.1828 6.75156 13.1828C6.53906 13.1828 6.36406 13.1141 6.22656 12.9766L2.77656 9.52656C2.70156 9.45156 2.64844 9.37031 2.61719 9.28281C2.58594 9.19531 2.57031 9.10156 2.57031 9.00156C2.57031 8.90156 2.58594 8.80781 2.61719 8.72031C2.64844 8.63281 2.70156 8.55156 2.77656 8.47656L6.22656 5.02656C6.36406 4.88906 6.53906 4.82031 6.75156 4.82031C6.96406 4.82031 7.13906 4.88906 7.27656 5.02656C7.41406 5.16406 7.48281 5.33906 7.48281 5.55156C7.48281 5.76406 7.41406 5.93906 7.27656 6.07656L5.10156 8.25156H14.2516V6.00156C14.2516 5.78906 14.3234 5.61094 14.4672 5.46719C14.6109 5.32344 14.7891 5.25156 15.0016 5.25156C15.2141 5.25156 15.3922 5.32344 15.5359 5.46719C15.6797 5.61094 15.7516 5.78906 15.7516 6.00156V8.25156C15.7516 8.66406 15.6047 9.01719 15.3109 9.31094C15.0172 9.60469 14.6641 9.75156 14.2516 9.75156H5.10156Z"
        fill="currentColor" />
    </svg>
  )
};
