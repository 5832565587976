import { IconProps } from 'components/icons/types';

export const ActivityIcon = (props: IconProps) => {
  const { width = 20, height = 20, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16732 15.8346C3.93121 15.8346 3.73329 15.7548 3.57357 15.5951C3.41385 15.4353 3.33398 15.2374 3.33398 15.0013C3.33398 14.7652 3.41385 14.5673 3.57357 14.4076C3.73329 14.2478 3.93121 14.168 4.16732 14.168H5.00065V8.33464C5.00065 7.18186 5.34787 6.15755 6.04232 5.26172C6.73676 4.36589 7.63954 3.77908 8.75065 3.5013V2.91797C8.75065 2.57075 8.87218 2.27561 9.11523 2.03255C9.35829 1.7895 9.65343 1.66797 10.0007 1.66797C10.3479 1.66797 10.643 1.7895 10.8861 2.03255C11.1291 2.27561 11.2507 2.57075 11.2507 2.91797V3.5013C12.3618 3.77908 13.2645 4.36589 13.959 5.26172C14.6534 6.15755 15.0007 7.18186 15.0007 8.33464V14.168H15.834C16.0701 14.168 16.268 14.2478 16.4277 14.4076C16.5875 14.5673 16.6673 14.7652 16.6673 15.0013C16.6673 15.2374 16.5875 15.4353 16.4277 15.5951C16.268 15.7548 16.0701 15.8346 15.834 15.8346H4.16732ZM10.0007 18.3346C9.54232 18.3346 9.14996 18.1714 8.82357 17.8451C8.49718 17.5187 8.33398 17.1263 8.33398 16.668H11.6673C11.6673 17.1263 11.5041 17.5187 11.1777 17.8451C10.8513 18.1714 10.459 18.3346 10.0007 18.3346ZM6.66732 14.168H13.334V8.33464C13.334 7.41797 13.0076 6.63325 12.3548 5.98047C11.702 5.32769 10.9173 5.0013 10.0007 5.0013C9.08398 5.0013 8.29926 5.32769 7.64648 5.98047C6.99371 6.63325 6.66732 7.41797 6.66732 8.33464V14.168Z"
        fill="currentColor" />
    </svg>
  )
};
