import { IconProps } from 'components/icons/types';

export const CopyIcon = (props: IconProps) => {
  const { width = 18, height = 18, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.75 13.5C6.3375 13.5 5.98438 13.3531 5.69063 13.0594C5.39688 12.7656 5.25 12.4125 5.25 12V3C5.25 2.5875 5.39688 2.23438 5.69063 1.94063C5.98438 1.64687 6.3375 1.5 6.75 1.5H13.5C13.9125 1.5 14.2656 1.64687 14.5594 1.94063C14.8531 2.23438 15 2.5875 15 3V12C15 12.4125 14.8531 12.7656 14.5594 13.0594C14.2656 13.3531 13.9125 13.5 13.5 13.5H6.75ZM6.75 12H13.5V3H6.75V12ZM3.75 16.5C3.3375 16.5 2.98438 16.3531 2.69063 16.0594C2.39687 15.7656 2.25 15.4125 2.25 15V4.5H3.75V15H12V16.5H3.75Z"
        fill="currentColor" />
    </svg>
  )
};
