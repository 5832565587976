import { IconProps } from 'components/icons/types';

export const PlayButton = (props: IconProps) => {
  const { width = 20, height = 21, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.65 14.1484L13.525 11.0234C13.7583 10.8734 13.875 10.6651 13.875 10.3984C13.875 10.1318 13.7583 9.92344 13.525 9.77344L8.65 6.64844C8.4 6.48177 8.14583 6.46927 7.8875 6.61094C7.62917 6.7526 7.5 6.97344 7.5 7.27344V13.5234C7.5 13.8234 7.62917 14.0443 7.8875 14.1859C8.14583 14.3276 8.4 14.3151 8.65 14.1484ZM10 20.3984C8.61667 20.3984 7.31667 20.1359 6.1 19.6109C4.88333 19.0859 3.825 18.3734 2.925 17.4734C2.025 16.5734 1.3125 15.5151 0.7875 14.2984C0.2625 13.0818 0 11.7818 0 10.3984C0 9.0151 0.2625 7.7151 0.7875 6.49844C1.3125 5.28177 2.025 4.22344 2.925 3.32344C3.825 2.42344 4.88333 1.71094 6.1 1.18594C7.31667 0.660937 8.61667 0.398438 10 0.398438C11.3833 0.398438 12.6833 0.660937 13.9 1.18594C15.1167 1.71094 16.175 2.42344 17.075 3.32344C17.975 4.22344 18.6875 5.28177 19.2125 6.49844C19.7375 7.7151 20 9.0151 20 10.3984C20 11.7818 19.7375 13.0818 19.2125 14.2984C18.6875 15.5151 17.975 16.5734 17.075 17.4734C16.175 18.3734 15.1167 19.0859 13.9 19.6109C12.6833 20.1359 11.3833 20.3984 10 20.3984ZM10 18.3984C12.2333 18.3984 14.125 17.6234 15.675 16.0734C17.225 14.5234 18 12.6318 18 10.3984C18 8.1651 17.225 6.27344 15.675 4.72344C14.125 3.17344 12.2333 2.39844 10 2.39844C7.76667 2.39844 5.875 3.17344 4.325 4.72344C2.775 6.27344 2 8.1651 2 10.3984C2 12.6318 2.775 14.5234 4.325 16.0734C5.875 17.6234 7.76667 18.3984 10 18.3984Z"
        fill="currentColor" />
    </svg>
  )
};
