import { IconProps } from 'components/icons/types';

export const CloseIcon = (props: IconProps) => {
  const { width = 32, height = 32, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 32 32" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0004 17.8669L12.1337 21.7336C11.8893 21.978 11.5782 22.1003 11.2004 22.1003C10.8226 22.1003 10.5115 21.978 10.2671 21.7336C10.0226 21.4892 9.90039 21.178 9.90039 20.8003C9.90039 20.4225 10.0226 20.1114 10.2671 19.8669L14.1337 16.0003L10.2671 12.1669C10.0226 11.9225 9.90039 11.6114 9.90039 11.2336C9.90039 10.8558 10.0226 10.5447 10.2671 10.3003C10.5115 10.0558 10.8226 9.93359 11.2004 9.93359C11.5782 9.93359 11.8893 10.0558 12.1337 10.3003L16.0004 14.1669L19.8337 10.3003C20.0782 10.0558 20.3893 9.93359 20.7671 9.93359C21.1448 9.93359 21.4559 10.0558 21.7004 10.3003C21.9671 10.5669 22.1004 10.8836 22.1004 11.2503C22.1004 11.6169 21.9671 11.9225 21.7004 12.1669L17.8337 16.0003L21.7004 19.8669C21.9448 20.1114 22.0671 20.4225 22.0671 20.8003C22.0671 21.178 21.9448 21.4892 21.7004 21.7336C21.4337 22.0003 21.1171 22.1336 20.7504 22.1336C20.3837 22.1336 20.0782 22.0003 19.8337 21.7336L16.0004 17.8669Z"
        fill="currentColor" />
    </svg>
  )
};
