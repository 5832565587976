import { IconProps } from 'components/icons/types';

export const ShareIcon = (props: IconProps) => {
  const { width = 24, height = 25, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9998 8.79844L7.0998 17.6984C6.91647 17.8818 6.68314 17.9734 6.3998 17.9734C6.11647 17.9734 5.88314 17.8818 5.6998 17.6984C5.51647 17.5151 5.4248 17.2818 5.4248 16.9984C5.4248 16.7151 5.51647 16.4818 5.6998 16.2984L14.5998 7.39844H6.9998C6.71647 7.39844 6.47897 7.3026 6.2873 7.11094C6.09564 6.91927 5.9998 6.68177 5.9998 6.39844C5.9998 6.1151 6.09564 5.8776 6.2873 5.68594C6.47897 5.49427 6.71647 5.39844 6.9998 5.39844H16.9998C17.2831 5.39844 17.5206 5.49427 17.7123 5.68594C17.904 5.8776 17.9998 6.1151 17.9998 6.39844V16.3984C17.9998 16.6818 17.904 16.9193 17.7123 17.1109C17.5206 17.3026 17.2831 17.3984 16.9998 17.3984C16.7165 17.3984 16.479 17.3026 16.2873 17.1109C16.0956 16.9193 15.9998 16.6818 15.9998 16.3984V8.79844Z"
        fill="currentColor" />
    </svg>
  )
};
