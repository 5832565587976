import { IconProps } from 'components/icons/types';

export const MailIcon = (props: IconProps) => {
  const { width = 28, height = 28, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 28 28" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66536 23.3346C4.0237 23.3346 3.47439 23.1062 3.01745 22.6492C2.5605 22.1923 2.33203 21.643 2.33203 21.0013V7.0013C2.33203 6.35964 2.5605 5.81033 3.01745 5.35339C3.47439 4.89644 4.0237 4.66797 4.66536 4.66797H23.332C23.9737 4.66797 24.523 4.89644 24.9799 5.35339C25.4369 5.81033 25.6654 6.35964 25.6654 7.0013V21.0013C25.6654 21.643 25.4369 22.1923 24.9799 22.6492C24.523 23.1062 23.9737 23.3346 23.332 23.3346H4.66536ZM23.332 9.33464L14.6112 14.7888C14.514 14.8471 14.4119 14.8909 14.3049 14.9201C14.198 14.9492 14.0959 14.9638 13.9987 14.9638C13.9015 14.9638 13.7994 14.9492 13.6924 14.9201C13.5855 14.8909 13.4834 14.8471 13.3862 14.7888L4.66536 9.33464V21.0013H23.332V9.33464ZM13.9987 12.8346L23.332 7.0013H4.66536L13.9987 12.8346ZM4.66536 9.6263V7.90547V7.93464V7.92005V9.6263Z"
        fill="currentColor" />
    </svg>
  )
};
