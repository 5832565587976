import { IconProps } from 'components/icons/types';

export const ArrowLeftIcon = (props: IconProps) => {
  const { width = 21, height = 20, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 21 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M16.3346 10.0013H4.66797M4.66797 10.0013L10.5013 15.8346M4.66797 10.0013L10.5013 4.16797"
            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
};
