import { IconProps } from 'components/icons/types';

export const BadgeIcon = (props: IconProps) => {
  const { width = 16, height = 16, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.80161 14.2013C7.5905 14.4124 7.34883 14.4596 7.07661 14.343C6.80439 14.2263 6.66828 14.018 6.66828 13.718V9.33463H2.28494C1.98494 9.33463 1.77661 9.19852 1.65994 8.9263C1.54328 8.65408 1.5905 8.41241 1.80161 8.2013L6.95161 3.0513C7.07383 2.92908 7.2155 2.83464 7.37661 2.76797C7.53772 2.7013 7.70717 2.66797 7.88494 2.66797H12.0016C12.3683 2.66797 12.6822 2.79852 12.9433 3.05964C13.2044 3.32075 13.3349 3.63464 13.3349 4.0013V8.11797C13.3349 8.29575 13.3016 8.46519 13.2349 8.6263C13.1683 8.78741 13.0738 8.92908 12.9516 9.0513L7.80161 14.2013ZM10.6683 6.0013V9.4513L12.0016 8.11797V4.0013H7.88494L6.55161 5.33464H10.0016C10.1905 5.33464 10.3488 5.39852 10.4766 5.5263C10.6044 5.65408 10.6683 5.81241 10.6683 6.0013ZM8.00161 8.66797V12.118L9.33494 10.7846V6.66797H5.21828L3.88494 8.0013H7.33494C7.52383 8.0013 7.68217 8.06519 7.80994 8.19297C7.93772 8.32075 8.00161 8.47908 8.00161 8.66797Z"
        fill="currentColor" />
    </svg>
  )
};
