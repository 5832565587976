import { IconProps } from 'components/icons/types';

export const CheckIcon = (props: IconProps) => {
  const { width = 20, height = 20, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.95745 12.625L15.0199 5.5625C15.1866 5.39583 15.3811 5.3125 15.6033 5.3125C15.8255 5.3125 16.0199 5.39583 16.1866 5.5625C16.3533 5.72917 16.4366 5.92708 16.4366 6.15625C16.4366 6.38542 16.3533 6.58333 16.1866 6.75L8.54078 14.4167C8.37411 14.5833 8.17967 14.6667 7.95745 14.6667C7.73523 14.6667 7.54078 14.5833 7.37411 14.4167L3.79078 10.8333C3.62411 10.6667 3.54425 10.4688 3.5512 10.2396C3.55814 10.0104 3.64495 9.8125 3.81161 9.64583C3.97828 9.47917 4.1762 9.39583 4.40536 9.39583C4.63453 9.39583 4.83245 9.47917 4.99911 9.64583L7.95745 12.625Z"
        fill="currentColor" />
    </svg>
  )
};
