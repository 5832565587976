import { IconProps } from 'components/icons/types';

export const WhatsappIcon = (props: IconProps) => {
  const { width = 145, height = 145, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 145 145" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_3569_89368" maskUnits="userSpaceOnUse" x="6" y="6" width="132"
            height="132">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M64.7008 9.43538C62.3689 8.59641 59.3337 7.58967 57.878 7.32217C55.6559 6.91406 53.4623 6.76604 51.5181 6.9424C49.2339 7.14952 47.0397 7.70217 44.9847 8.58008C42.9251 9.45946 41.0052 10.6653 39.2737 12.1765C37.8099 13.4538 36.4081 15.1316 35.1725 17.0089C34.3504 18.2583 32.9716 21.1701 31.9618 23.4508C31.5494 24.3831 31.0419 25.6001 30.8159 26.1646L10.48 76.9237C10.2549 77.4859 9.78172 78.7171 9.43609 79.6774C8.5972 82.0091 7.59046 85.0443 7.32313 86.5001C6.91491 88.722 6.767 90.9158 6.94318 92.8599C7.15041 95.1443 7.70303 97.3381 8.58086 99.3934C9.46032 101.453 10.6664 103.373 12.1773 105.104C13.4549 106.568 15.1326 107.97 17.0097 109.205C18.2591 110.028 21.1711 111.407 23.4518 112.416C24.3837 112.829 25.6009 113.336 26.1654 113.562L76.9245 133.898C77.4869 134.123 78.718 134.597 79.678 134.942C82.0099 135.781 85.0453 136.788 86.5009 137.055C88.723 137.463 90.9169 137.611 92.8607 137.435C95.145 137.228 97.3391 136.675 99.3941 135.797C101.454 134.918 103.373 133.712 105.105 132.201C106.569 130.923 107.971 129.246 109.206 127.368C110.029 126.119 111.407 123.207 112.417 120.926C112.83 119.994 113.337 118.777 113.563 118.213L133.899 67.4536C134.124 66.8912 134.597 65.66 134.943 64.6998C135.781 62.3681 136.788 59.3329 137.056 57.8772C137.464 55.6551 137.612 53.4614 137.436 51.5173C137.229 49.233 136.676 47.0389 135.798 44.9838C134.918 42.9245 133.713 41.0043 132.202 39.2729C130.924 37.8091 129.246 36.4071 127.369 35.1717C126.12 34.3497 123.208 32.9707 120.927 31.9612C119.995 31.5484 118.778 31.0411 118.213 30.8151L67.4543 10.4792C66.8919 10.254 65.6608 9.78068 64.7008 9.43538Z"
              fill="white" />
      </mask>
      <g mask="url(#mask0_3569_89368)">
        <path
          d="M64.7015 9.43538C62.3697 8.59641 59.3344 7.58967 57.8787 7.32217C55.6566 6.91406 53.463 6.76604 51.5188 6.9424C49.2347 7.14952 47.0405 7.70217 44.9854 8.58008C42.9258 9.45946 41.0059 10.6653 39.2744 12.1765C37.8107 13.4538 36.4088 15.1316 35.1733 17.0089C34.3512 18.2583 32.9723 21.1701 31.9626 23.4508C31.5501 24.3831 31.0427 25.6001 30.8166 26.1646L10.4807 76.9237C10.2556 77.4859 9.78245 78.7171 9.43683 79.6774C8.59793 82.0091 7.59119 85.0443 7.32387 86.5001C6.91565 88.722 6.76773 90.9158 6.94391 92.8599C7.15114 95.1443 7.70376 97.3381 8.5816 99.3934C9.46106 101.453 10.6671 103.373 12.178 105.104C13.4556 106.568 15.1333 107.97 17.0104 109.205C18.2598 110.028 21.1718 111.407 23.4526 112.416C24.3844 112.829 25.6016 113.336 26.1661 113.562L76.9252 133.898C77.4876 134.123 78.7187 134.597 79.6787 134.942C82.0107 135.781 85.046 136.788 86.5016 137.055C88.7238 137.463 90.9176 137.611 92.8614 137.435C95.1457 137.228 97.3399 136.675 99.3949 135.797C101.454 134.918 103.374 133.712 105.106 132.201C106.569 130.923 107.972 129.246 109.207 127.368C110.029 126.119 111.408 123.207 112.418 120.926C112.83 119.994 113.338 118.777 113.564 118.213L133.9 67.4536C134.125 66.8912 134.598 65.66 134.943 64.6998C135.782 62.3681 136.789 59.3329 137.056 57.8772C137.465 55.6551 137.613 53.4614 137.436 51.5173C137.229 49.233 136.676 47.0389 135.799 44.9838C134.919 42.9245 133.713 41.0043 132.202 39.2729C130.925 37.8091 129.247 36.4071 127.37 35.1717C126.12 34.3497 123.208 32.9707 120.928 31.9612C119.996 31.5484 118.779 31.0411 118.214 30.8151L67.455 10.4792C66.8927 10.254 65.6615 9.78068 64.7015 9.43538Z"
          fill="url(#paint0_linear_3569_89368)" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M87.3404 86.1424C86.6062 85.3152 82.981 81.2867 82.2619 80.6433C81.5429 80 80.9922 79.6017 80.0121 80.2755C79.0319 80.9493 76.4443 82.3122 75.6632 82.7104C74.8823 83.1084 74.316 82.9708 73.5819 82.1434C72.8475 81.3164 70.2771 79.1622 68.0223 74.6303C66.2672 71.1034 65.6282 67.3592 65.4605 66.2257C65.2929 65.0923 65.9704 64.7872 66.6135 64.5136C67.191 64.2678 67.9608 63.8092 68.6346 63.457C69.3084 63.1048 69.6148 62.7832 70.1662 62.2933C70.7176 61.8031 70.7792 61.2058 70.7336 60.6543C70.6881 60.1029 70.6611 54.8494 70.6319 52.7051C70.6034 50.6168 69.7493 50.5558 69.1969 50.302C68.6726 50.0611 68.0621 49.8101 67.45 49.565C66.8378 49.3198 65.7511 49.1513 64.5413 49.733C63.3317 50.3149 60.0703 51.586 58.2611 56.104C56.4518 60.6221 57.9941 66.3047 58.2079 67.1012C58.4217 67.8978 60.7245 79.5785 68.3434 87.2432C70.1554 89.0662 71.6397 90.3159 72.8036 91.2731C74.7237 92.8525 76.7649 93.5556 78.4416 93.9528C80.3113 94.3957 84.7637 93.9076 86.3875 92.0698C88.0111 90.232 88.7471 88.3941 88.671 87.9193C88.5948 87.4446 88.0747 86.9696 87.3404 86.1424ZM61.4295 102.306L61.4171 102.301C55.9371 100.104 51.1508 96.4795 47.5754 91.8195L46.725 90.7109L33.9506 89.1133L41.5485 79.0813L41.2847 77.6352C40.174 71.5492 40.8094 65.2835 43.1221 59.5149C49.8706 42.682 69.0577 34.4721 85.9059 41.219C94.0629 44.4891 100.458 50.7405 103.914 58.8213C107.37 66.9022 107.472 75.8451 104.201 84.0027C97.452 100.837 78.2647 109.048 61.4295 102.306ZM110.059 56.1901C105.902 46.4677 98.2068 38.9467 88.3777 35.0062C68.1223 26.8949 45.0376 36.7725 36.9182 57.0248C34.3227 63.4986 33.4838 70.4966 34.4687 77.3553L21.6318 94.3047L43.1587 96.9975C47.3543 102.074 52.78 106.036 58.9295 108.502L58.9445 108.508L58.9447 108.508C79.1982 116.618 102.285 106.739 110.405 86.4865C114.339 76.6718 114.217 65.9125 110.059 56.1901Z"
              fill="white" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_3569_89368" x1="0.000861713" y1="103.094" x2="41.2849" y2="-0.000705847"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#25CF43" />
          <stop offset="1" stopColor="#61FD7D" />
        </linearGradient>
      </defs>
    </svg>

  )
};
