import { IconProps } from 'components/icons/types';

export const KeyIcon = (props: IconProps) => {
  const { width = 28, height = 28, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 28 28" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.16797 16.3333C7.5263 16.3333 6.977 16.1049 6.52005 15.6479C6.06311 15.191 5.83464 14.6417 5.83464 14C5.83464 13.3583 6.06311 12.809 6.52005 12.3521C6.977 11.8951 7.5263 11.6667 8.16797 11.6667C8.80964 11.6667 9.35894 11.8951 9.81589 12.3521C10.2728 12.809 10.5013 13.3583 10.5013 14C10.5013 14.6417 10.2728 15.191 9.81589 15.6479C9.35894 16.1049 8.80964 16.3333 8.16797 16.3333ZM8.16797 21C6.22352 21 4.57075 20.3194 3.20964 18.9583C1.84852 17.5972 1.16797 15.9444 1.16797 14C1.16797 12.0556 1.84852 10.4028 3.20964 9.04167C4.57075 7.68056 6.22352 7 8.16797 7C9.47075 7 10.652 7.32083 11.7117 7.9625C12.7714 8.60417 13.6124 9.45 14.2346 10.5H24.0055C24.161 10.5 24.3117 10.5292 24.4576 10.5875C24.6034 10.6458 24.7346 10.7333 24.8513 10.85L27.1846 13.1833C27.3013 13.3 27.3839 13.4264 27.4326 13.5625C27.4812 13.6986 27.5055 13.8444 27.5055 14C27.5055 14.1556 27.4812 14.3014 27.4326 14.4375C27.3839 14.5736 27.3013 14.7 27.1846 14.8167L23.4805 18.5208C23.3832 18.6181 23.2666 18.6958 23.1305 18.7542C22.9944 18.8125 22.8582 18.8514 22.7221 18.8708C22.586 18.8903 22.4499 18.8806 22.3138 18.8417C22.1777 18.8028 22.0513 18.7347 21.9346 18.6375L20.418 17.5L18.7555 18.7542C18.6582 18.8319 18.5513 18.8903 18.4346 18.9292C18.318 18.9681 18.2013 18.9875 18.0846 18.9875C17.968 18.9875 17.8464 18.9681 17.7201 18.9292C17.5937 18.8903 17.4819 18.8319 17.3846 18.7542L15.6055 17.5H14.2346C13.6124 18.55 12.7714 19.3958 11.7117 20.0375C10.652 20.6792 9.47075 21 8.16797 21ZM8.16797 18.6667C9.25686 18.6667 10.2145 18.3361 11.0409 17.675C11.8673 17.0139 12.4166 16.1778 12.6888 15.1667H16.3346L18.0263 16.3625L20.418 14.5833L22.4888 16.1875L24.6763 14L23.5096 12.8333H12.6888C12.4166 11.8222 11.8673 10.9861 11.0409 10.325C10.2145 9.66389 9.25686 9.33333 8.16797 9.33333C6.88464 9.33333 5.78602 9.79028 4.87214 10.7042C3.95825 11.6181 3.5013 12.7167 3.5013 14C3.5013 15.2833 3.95825 16.3819 4.87214 17.2958C5.78602 18.2097 6.88464 18.6667 8.16797 18.6667Z"
        fill="currentColor" />
    </svg>
  )
};
