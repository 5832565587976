import { IconProps } from 'components/icons/types';

export const LockIcon = (props: IconProps) => {
  const { width = 28, height = 28, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 28 28" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8346 18.668V21.0013C12.8346 21.3319 12.9464 21.6089 13.1701 21.8326C13.3937 22.0562 13.6707 22.168 14.0013 22.168C14.3319 22.168 14.6089 22.0562 14.8326 21.8326C15.0562 21.6089 15.168 21.3319 15.168 21.0013V18.668H17.5013C17.8319 18.668 18.1089 18.5562 18.3326 18.3326C18.5562 18.1089 18.668 17.8319 18.668 17.5013C18.668 17.1707 18.5562 16.8937 18.3326 16.6701C18.1089 16.4464 17.8319 16.3346 17.5013 16.3346H15.168V14.0013C15.168 13.6707 15.0562 13.3937 14.8326 13.1701C14.6089 12.9464 14.3319 12.8346 14.0013 12.8346C13.6707 12.8346 13.3937 12.9464 13.1701 13.1701C12.9464 13.3937 12.8346 13.6707 12.8346 14.0013V16.3346H10.5013C10.1707 16.3346 9.89366 16.4464 9.67005 16.6701C9.44644 16.8937 9.33464 17.1707 9.33464 17.5013C9.33464 17.8319 9.44644 18.1089 9.67005 18.3326C9.89366 18.5562 10.1707 18.668 10.5013 18.668H12.8346ZM7.0013 25.668C6.35964 25.668 5.81033 25.4395 5.35339 24.9826C4.89644 24.5256 4.66797 23.9763 4.66797 23.3346V11.668C4.66797 11.0263 4.89644 10.477 5.35339 10.0201C5.81033 9.56311 6.35964 9.33463 7.0013 9.33463H8.16797V7.0013C8.16797 5.38741 8.73672 4.01172 9.87422 2.87422C11.0117 1.73672 12.3874 1.16797 14.0013 1.16797C15.6152 1.16797 16.9909 1.73672 18.1284 2.87422C19.2659 4.01172 19.8346 5.38741 19.8346 7.0013V9.33463H21.0013C21.643 9.33463 22.1923 9.56311 22.6492 10.0201C23.1062 10.477 23.3346 11.0263 23.3346 11.668V23.3346C23.3346 23.9763 23.1062 24.5256 22.6492 24.9826C22.1923 25.4395 21.643 25.668 21.0013 25.668H7.0013ZM7.0013 23.3346H21.0013V11.668H7.0013V23.3346ZM10.5013 9.33463H17.5013V7.0013C17.5013 6.02908 17.161 5.20269 16.4805 4.52214C15.7999 3.84158 14.9735 3.5013 14.0013 3.5013C13.0291 3.5013 12.2027 3.84158 11.5221 4.52214C10.8416 5.20269 10.5013 6.02908 10.5013 7.0013V9.33463Z"
        fill="currentColor" />
    </svg>
  )
};
