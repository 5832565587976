import { IconProps } from 'components/icons/types';

export const WhatsappIcon = (props: IconProps) => {
  const { width = 24, height = 25, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M17.4976 14.7818C17.199 14.6331 15.7311 13.9143 15.4574 13.8152C15.1837 13.7161 14.9847 13.6665 14.7857 13.964C14.5867 14.2614 14.0145 14.9307 13.8403 15.1291C13.6662 15.3274 13.4921 15.3522 13.1935 15.2035C12.8949 15.0548 11.9329 14.7411 10.7925 13.7288C9.90484 12.941 9.30557 11.968 9.13135 11.6705C8.95719 11.3731 9.11278 11.2123 9.26229 11.0641C9.39652 10.931 9.56077 10.7171 9.71005 10.5435C9.85932 10.37 9.90907 10.246 10.0086 10.0478C10.1081 9.84943 10.0583 9.67591 9.98364 9.52718C9.90901 9.37845 9.31183 7.91598 9.06298 7.32107C8.82062 6.7417 8.57446 6.82011 8.39119 6.81103C8.21723 6.80242 8.01801 6.8006 7.81896 6.80061C7.61992 6.80063 7.2965 6.87504 7.0228 7.17247C6.74916 7.46995 5.97794 8.18892 5.97804 9.65138C5.97814 11.1139 7.04808 12.5268 7.19738 12.7251C7.34668 12.9234 9.30291 15.9245 12.298 17.2114C13.0103 17.5175 13.5665 17.7002 14.0001 17.8372C14.7154 18.0633 15.3662 18.0313 15.8807 17.9548C16.4543 17.8694 17.6471 17.2358 17.8958 16.5417C18.1445 15.8476 18.1445 15.2527 18.0699 15.1288C17.9952 15.0049 17.7962 14.9305 17.4976 14.7818ZM12.052 22.1835L12.048 22.1835C10.2659 22.1829 8.51796 21.7066 6.99308 20.8061L6.63039 20.5919L2.87159 21.5735L3.87466 17.9261L3.63846 17.5522C2.64421 15.9786 2.11903 14.1597 2.11965 12.2921C2.12146 6.84247 6.57645 2.4085 12.0546 2.40813C14.7073 2.40898 17.201 3.43828 19.0762 5.30636C20.9515 7.17449 21.9837 9.65778 21.9829 12.2988C21.981 17.7488 17.5261 22.1831 12.052 22.1835ZM20.5024 3.88618C18.2463 1.63856 15.246 0.400278 12.0496 0.399238C5.46347 0.399683 0.103531 5.73433 0.101339 12.291C0.100621 14.3869 0.65095 16.4328 1.6967 18.236L0.00195312 24.3984L6.3362 22.7444C8.08154 23.6917 10.0466 24.1907 12.0464 24.1915L12.0513 24.1915L12.0514 24.1915C18.6368 24.191 23.9972 18.8557 23.9995 12.2989C24.0005 9.12144 22.7586 6.13374 20.5024 3.88618Z"
            fill="currentColor" />
    </svg>
  )
};
