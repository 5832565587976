import { IconProps } from 'components/icons/types';

export const MagicSearchIcon = (props: IconProps) => {
  const { width = 20, height = 20, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1938 7.35973L13.7084 6.76632L13.1848 3.28165C13.1059 2.71665 12.4088 2.53989 12.0682 2.95338L9.86334 5.65211L6.57662 4.33589C5.88269 4.09284 5.51364 4.64837 5.72182 5.15655L6.9709 8.51181L4.24248 10.6487C4.04376 10.8128 3.94598 11.0433 4.01222 11.3052C4.07846 11.5357 4.27402 11.7313 4.53898 11.7661L7.17593 12.2143L2.46979 17.0625C2.20799 17.3245 2.20799 17.7191 2.46979 17.9842C2.73159 18.2462 3.12587 18.2462 3.39083 17.9842L8.13797 13.0949L8.55118 15.8126C8.57641 16.3081 9.27666 16.5891 9.66778 16.1409L11.8694 13.4453L15.1562 14.7615C15.7996 15.0109 16.1214 14.4932 16.0425 14.1365C16.0204 14.0387 14.7619 10.6171 14.7619 10.6171L17.4903 8.48025C17.8941 8.14252 17.7742 7.4702 17.1938 7.35973ZM13.5444 9.89432C13.3141 10.0585 13.2479 10.3552 13.3456 10.6171L14.232 12.9844L11.8978 12.0312C11.6676 11.9334 11.3395 11.9996 11.1755 12.2301L9.59839 14.1713L9.20411 11.6714C9.17256 11.4094 8.9423 11.179 8.67735 11.1443L6.17918 10.7181L8.15059 9.1715C8.38085 9.00737 8.44709 8.71067 8.34931 8.44869L7.46296 6.08138L9.79711 7.03462C10.0274 7.13246 10.3554 7.06618 10.5194 6.83576L12.0966 4.89457L12.4908 7.39445C12.5224 7.65643 12.7526 7.88685 13.0176 7.92157L15.5158 8.34768L13.5444 9.89432Z"
        fill="currentColor" />
    </svg>
  )
};
