import { IconProps } from 'components/icons/types';

export const PlusIcon = (props: IconProps) => {
  const { width = 20, height = 20, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.16602 10.8327H4.99935C4.76324 10.8327 4.56532 10.7528 4.4056 10.5931C4.24588 10.4334 4.16602 10.2355 4.16602 9.99935C4.16602 9.76324 4.24588 9.56532 4.4056 9.4056C4.56532 9.24588 4.76324 9.16602 4.99935 9.16602H9.16602V4.99935C9.16602 4.76324 9.24588 4.56532 9.4056 4.4056C9.56532 4.24588 9.76324 4.16602 9.99935 4.16602C10.2355 4.16602 10.4334 4.24588 10.5931 4.4056C10.7528 4.56532 10.8327 4.76324 10.8327 4.99935V9.16602H14.9993C15.2355 9.16602 15.4334 9.24588 15.5931 9.4056C15.7528 9.56532 15.8327 9.76324 15.8327 9.99935C15.8327 10.2355 15.7528 10.4334 15.5931 10.5931C15.4334 10.7528 15.2355 10.8327 14.9993 10.8327H10.8327V14.9993C10.8327 15.2355 10.7528 15.4334 10.5931 15.5931C10.4334 15.7528 10.2355 15.8327 9.99935 15.8327C9.76324 15.8327 9.56532 15.7528 9.4056 15.5931C9.24588 15.4334 9.16602 15.2355 9.16602 14.9993V10.8327Z"
        fill="currentColor" />
    </svg>
  )
};
