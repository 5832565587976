import { IconProps } from 'components/icons/types';

export const DotsIcon = (props: IconProps) => {
  const { width = 16, height = 16, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 13.3346C3.44444 13.3346 2.97222 13.1402 2.58333 12.7513C2.19444 12.3624 2 11.8902 2 11.3346C2 10.7791 2.19444 10.3069 2.58333 9.91797C2.97222 9.52908 3.44444 9.33464 4 9.33464C4.55556 9.33464 5.02778 9.52908 5.41667 9.91797C5.80556 10.3069 6 10.7791 6 11.3346C6 11.8902 5.80556 12.3624 5.41667 12.7513C5.02778 13.1402 4.55556 13.3346 4 13.3346ZM12 13.3346C11.4444 13.3346 10.9722 13.1402 10.5833 12.7513C10.1944 12.3624 10 11.8902 10 11.3346C10 10.7791 10.1944 10.3069 10.5833 9.91797C10.9722 9.52908 11.4444 9.33464 12 9.33464C12.5556 9.33464 13.0278 9.52908 13.4167 9.91797C13.8056 10.3069 14 10.7791 14 11.3346C14 11.8902 13.8056 12.3624 13.4167 12.7513C13.0278 13.1402 12.5556 13.3346 12 13.3346ZM8 6.66797C7.44444 6.66797 6.97222 6.47352 6.58333 6.08464C6.19444 5.69575 6 5.22352 6 4.66797C6 4.11241 6.19444 3.64019 6.58333 3.2513C6.97222 2.86241 7.44444 2.66797 8 2.66797C8.55556 2.66797 9.02778 2.86241 9.41667 3.2513C9.80556 3.64019 10 4.11241 10 4.66797C10 5.22352 9.80556 5.69575 9.41667 6.08464C9.02778 6.47352 8.55556 6.66797 8 6.66797Z"
        fill="currentColor" />
    </svg>
  )
};
