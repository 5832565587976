import { IconProps } from 'components/icons/types';

export const LightningIcon = (props: IconProps) => {
  const { width = 20, height = 20, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.16835 12.0827L4.23085 11.4577C3.88363 11.416 3.65794 11.2285 3.55377 10.8952C3.4496 10.5618 3.52252 10.2771 3.77252 10.041L12.2934 1.87435C12.3628 1.8049 12.4461 1.75282 12.5434 1.7181C12.6406 1.68338 12.7725 1.66602 12.9392 1.66602C13.217 1.66602 13.4288 1.78407 13.5746 2.02018C13.7204 2.25629 13.7239 2.49935 13.585 2.74935L10.835 7.91602L15.7725 8.54102C16.1197 8.58268 16.3454 8.77018 16.4496 9.10352C16.5538 9.43685 16.4809 9.72157 16.2309 9.95768L7.71002 18.1243C7.64057 18.1938 7.55724 18.2459 7.46002 18.2806C7.3628 18.3153 7.23085 18.3327 7.06419 18.3327C6.78641 18.3327 6.5746 18.2146 6.42877 17.9785C6.28294 17.7424 6.27946 17.4994 6.41835 17.2494L9.16835 12.0827ZM10.46 13.1868L13.8142 9.97852L8.21002 9.27018L9.52252 6.83268L6.18919 10.041L11.7725 10.7285L10.46 13.1868Z"
        fill="currentColor" />
    </svg>
  )
};
